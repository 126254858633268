@import "src/app/common/utils/scss/main.scss";

.side-drawer {
  height: 100vh;
  width: 57.6%;
  background: $color-white;
  box-shadow: 0 2px 31px 0 rgba(0, 0, 0, 0.25);
  padding: 40px 60px 48px 60px;
  overflow-y: auto;
  z-index: 9999;
  animation-name: example2;
  animation-duration: 1s;
  position: fixed;
  top: 0;
  left: 100%;
  transform: translateX(0);
  /* changed it */
  transition: transform 0.4s ease-out;

  &[data-opened=true] {
    transform: translateX(-100%);
    /* changed it */
  }

  .side-drawer-header {
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    .side-drawer-header-title {
      font-size: 1.8rem;
      font-weight: 500;
      color: $color-black;
    }

    .side-drawer-header-close {
      cursor: pointer;
      font-size: 1.8rem;
      color: $color-black;
    }
  }

  .side-drawer-body {
    max-height: 80%;
  }

  .side-drawer-footer {
    position: absolute;
    bottom: 40px;
    right: 60px;
  }

  .side-drawer-subtitle {
    font-size: 0.9rem;
    font-weight: 600;
    color: $description-color;
    margin-bottom: 12px;
  }

  .side-drawer-description {
    font-size: 0.8rem;
    font-weight: 400;
    color: $description-color;
    margin-bottom: 20px;
  }

  .close-button {
    display: flex;
    align-items: center;
    justify-content: right;
    cursor: pointer;
  }
}
