.overlay-loader-main-cont {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    justify-content: center;
    align-items: center;
    background-color: rgba($color: #fff, $alpha: .9);
    display: none;
}

.overlay-loader-main-cont[data-visible=true] {
    display: flex;
}