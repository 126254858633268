@import 'variables';

.rdt_Table {
    border-collapse: collapse;

    .action-button {
        cursor: pointer;
    }
}

.rdt_TableBody {
    box-shadow: 2px 2px 4px 0 black;
}

.rdt_TableHeadRow {
    color: #6F6F6F;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 15px;

    span {
        cursor: pointer;
    }
}